.itemTableContainer {
  overflow: auto;
  height: 500px;
}
/* .itemTableContainer thead th { position: sticky; top: 0; z-index: 1; } */
/* .itemTableContainer tfoot th { position: sticky; bottom: 0; z-index: 1; } */

.itemTableContainer thead {
  position: sticky;
  inset-block-start: 0;
}

.itemTableContainer tfoot {
  position: sticky;
  inset-block-end: 0;
}

/* Just common table stuff. Really. */
.itemTableContainer > table {
  border-collapse: collapse;
  width: 100%;
}
.itemTableContainer > th,
td {
  padding: 8px 16px;
}
.itemTableContainer > table > thead > tr > th {
  background: #252a31;
}

.itemTableContainer table tfoot tr {
  background: #252a31;
}

.sortableTableHeader {
  user-select: none;
  min-width: 4em;
}

.sortableTableHeader:hover {
  filter: brightness(1.5);
}

.sortableTableHeader:active {
  filter: brightness(0.85);
}

.itemsView .tableFilters {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: baseline;
  gap: 10px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.itemsView .tableFilters .searchInput {
  flex-grow: 1;
}

.itemsView .tableFilters .raritySelect {
  width: 10em;
}

.itemsView .tableFilters .merchantTypeSelect {
  width: 12em;
}

.itemsView .tableFilters .itemTypeSelect {
  width: 12em;
}
