@import 'normalize.css';
@import '@blueprintjs/core/lib/css/blueprint.css';
@import '@blueprintjs/select/lib/css/blueprint-select.css';
@import '@blueprintjs/icons/lib/css/blueprint-icons.css';

.app {
  background-color: #252a31;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.app .appContent {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  overflow: hidden;
}

.app .appFooter {
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #363b43;
  padding: 0.3em;
  text-align: right;
}

.outline {
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}

@media print {
  body {
    margin: 0;
    color: #000;
    background-color: #fff;
  }

  .noPrint {
    display: none !important;
  }

  .app {
    margin: 0;
    color: #000;
    background-color: #fff;
  }

  .app .appContent {
    display: block;
    overflow: visible;
  }
}
