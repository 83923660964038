.homeView {
  margin: 2em;
}

.homeView h1 {
  margin-top: 0px;
}

.homeView .introText {
  text-align: justify;
}

.homeView .cardList {
  margin: 5em;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2em;
  align-items: top;
  justify-content: flex-start;
}

.homeView .overviewCard {
  width: 200px;
}

.homeView .overviewCard h5 {
  margin-top: 0px;
  margin-bottom: 1em;
}

.homeView .overviewCard .navButton {
  width: 100%;
}
